<template>
  <TopBar :hasMenu="false" />
  <main class="p-2 p-md-4">
    <div class="container my-2">
      <h1>Booking Confirmation</h1>
    </div>
  </main>
</template>

<script setup lang="ts">
import { useLocaleStore } from '@/stores/localeStore';

import TopBar from '@/components/TopBar.vue';

const props = defineProps<{
  locale: string;
}>();

const { setLocale } = useLocaleStore();
setLocale(props.locale);

</script>
