import { computed, ref } from "vue";
import { defineStore, storeToRefs } from "pinia";

import type { CheckoutRequest, CheckoutOptions, PaymentData } from "../types/checkout";
import type { PricesData, Price, AttributePrice } from "../types/prices";

import { useCustomerStore } from "./customerStore";
import { useProductSelectionsStore } from "./productSelectionsStore";
import { useProductStore } from "./productStore";

import { useStripePayment } from "../composables/useStripePayment";

export const useCheckoutStore = defineStore("checkoutStore", () => {
  const checkoutLoading = ref(false);
  const checkoutError = ref<string | null>(null);
  const formSubmitted = ref(false);
  const stripeToken = ref<string | null>(null);

  const checkoutRequest = ref<CheckoutRequest>({} as CheckoutRequest);
  const checkoutOptions = ref<CheckoutOptions>({
    successUrl: '',
    isPrepaid: false,
    paymentMethods: [],
    prices: {} as PricesData,
    amountToPay: 0,
    checkoutPage: '',
    bookingToken: '',
    isPaymentInProgress: false,
    termsAccepted: false,
    isSubmitDisabled: false,
  });
  const paymentData = ref<PaymentData>({} as PaymentData);

  const { productOptions } = storeToRefs(useProductSelectionsStore());
  const productStore = useProductStore();

  const getPrice = (prices: Price[], pax = 0): number => {
    if (!prices || !prices.length) return 0;
    const price = prices.find(p => pax >= p.min_participants && pax <= p.max_participants);
    return price ? parseFloat(price.selling_price) : 0;
  };

  const getTotalLineItemPrice = (prices: Price[], pax = 0): number => {
    if (!prices || pax === 0) return 0;
    return pax * getPrice(prices, pax);
  };

  const getAttributePrice = (prices: AttributePrice[], attrCount = 0): number => {
    if (!prices || !prices.length) return 0;
    if (prices.length === 1) return prices[0].selling_price;
    const price = prices.find(p => attrCount >= p.amount && attrCount <= p.amount);
    return price ? price.selling_price : 0;
  };

  const getTotalLineItemAttributePrice = (prices: AttributePrice[], attrCount = 0): number => {
    if (!prices || attrCount === 0) return 0;
    return attrCount * getAttributePrice(prices, attrCount);
  };

  const calculateGrossTotal = (): number => {
    const adultTotal = getTotalLineItemPrice(checkoutOptions.value.prices.prices, productOptions.value.selectedPax);
    const childrenTotal = getTotalLineItemPrice(checkoutOptions.value.prices.children_prices, productOptions.value.selectedChildrenPax);
    const bookingFee = productStore.bookingFee;

    const attributesTotal = productOptions.value.attributes.reduce((acc, attribute) => {
      return acc + getTotalLineItemAttributePrice(attribute.prices, productOptions.value.selectedAttributes[attribute.id]);
    }, 0);

    return adultTotal + childrenTotal + attributesTotal + bookingFee;
  };

  const updateAmountToPay = () => {
    checkoutOptions.value.amountToPay = calculateGrossTotal();
  };

  const checkMinMaxPax = async () => {
    checkoutLoading.value = true;
    checkoutError.value = null;

    try {
      const response = await fetch(`${import.meta.env.VITE_MINMAX_CHECK_API_URL}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(checkoutRequest.value),
      });

      if (!response.ok) throw new Error('Failed to check min max');
      return (await response.json()).data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      checkoutLoading.value = false;
    }
  };

  const createWebsiteBooking = async () => {
    checkoutLoading.value = true;
    checkoutError.value = null;

    const requestData = {
      ...checkoutRequest.value,
      payment_method: paymentData.value.payment_method,
    };

    try {
      const response = await fetch(`${import.meta.env.VITE_INTEGRATION_API_URL}/public/consumer`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) throw new Error('Failed to create booking');
      return (await response.json()).data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      checkoutLoading.value = false;
    }
  };

  const validateForm = (): boolean => {
    formSubmitted.value = true;
    return checkoutOptions.value.termsAccepted && !!checkoutRequest.value.payment_method;
  };

  const setupPaymentData = () => {
    paymentData.value = {
      payment_method: checkoutRequest.value.payment_method,
      customer_email: checkoutRequest.value.customer_email,
      return_url: checkoutOptions.value.checkoutPage,
    };

    switch (checkoutRequest.value.payment_method) {
      case 'ideal':
      case 'bancontact':
        paymentData.value.owner_name = `${checkoutRequest.value.first_name} ${checkoutRequest.value.last_name}`;
        if (checkoutRequest.value.payment_method === 'bancontact') {
          paymentData.value.preferred_language = checkoutRequest.value.tour_language;
        }
        break;
      case 'google_pay':
      case 'apple_pay':
        paymentData.value.payment_method = 'credit_card';
        break;
      default:
        paymentData.value.payment_method = 'credit_card';
        break;
    }
  };

  const initPaymentSession = async () => {
    const { initPaymentSession } = useStripePayment();

    stripeToken.value = await initPaymentSession(checkoutOptions.value.bookingToken, paymentData.value);

    if (isWallet.value) {
      console.log("TODO: setup wallet stuff");
    }
  };

  const redirectToConfirmation = () => {
    window.location.href = `${checkoutOptions.value.successUrl}?token=${checkoutOptions.value.bookingToken}&tourLang=${checkoutRequest.value.tour_language}`;
  };

  const isOrderPaymentConfirmed = async (): Promise<boolean> => {
    const { checkPaymentConfirmation } = useStripePayment();
    const response = await checkPaymentConfirmation(checkoutOptions.value.bookingToken);
    if (response.success) redirectToConfirmation();
    return response.success;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const customerStore = useCustomerStore();
    if (!customerStore.validate()) return;

    checkoutOptions.value.isSubmitDisabled = true;
    checkoutOptions.value.isPaymentInProgress = true;

    checkoutRequest.value = {
      ...checkoutRequest.value,
      ...customerStore.customer,
      pax: productOptions.value.selectedPax,
      children_pax: productOptions.value.selectedChildrenPax,
      attributes: Object.keys(productOptions.value.selectedAttributes).map((id) => ({
        id,
        amount: productOptions.value.selectedAttributes[id]
      })),
    };

    const minmaxResponse = await checkMinMaxPax();
    if (!minmaxResponse?.isValid) {
      console.log("PAD-2752: show minmax error");
      return;
    }

    setupPaymentData();
    const bookingResponse = await createWebsiteBooking();
    if (!bookingResponse) return;

    console.log("PAD-2764: create affiliate tracking");
    console.log("PAD-2750: create ortto contact");

    checkoutOptions.value.bookingToken = bookingResponse.token;

    if (checkoutOptions.value.isPrepaid && bookingResponse.payment_required) {
      await initPaymentSession();
    } else {
      redirectToConfirmation();
    }
  };

  const isWallet = computed(() => {
    return ['google_pay', 'apple_pay'].includes(checkoutRequest.value.payment_method);
  });

  const amountToPayInCents = computed(() => {
    console.log('amountToPayInCents', checkoutOptions.value.amountToPay);
    return Math.round(checkoutOptions.value.amountToPay * 100);
  });

  return {
    // State
    checkoutRequest,
    checkoutOptions,
    checkoutLoading,
    formSubmitted,
    stripeToken,
    paymentData,

    // Actions
    handleSubmit,
    validateForm,
    updateAmountToPay,
    isOrderPaymentConfirmed,

    // Helper Functions
    calculateGrossTotal,
    getTotalLineItemPrice,
    getPrice,
    getAttributePrice,
    getTotalLineItemAttributePrice,

    // Getters
    isWallet,
    amountToPayInCents,
  };
});