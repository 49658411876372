<template>
  <div class="tour-info-container p-3 border rounded-3">
    <div class="col text-start px-0">
      <div class="mb-1 fw-bold">{{ tourTitle }}</div>
      <div class="mb-1">{{ tourDate }} {{ $t('at') }} {{ params.start_time }}</div>
      <div class="mb-1">{{ $t('language') }}: {{ params.lang ? siteLanguages[params.lang].translated_name : params.lang }}</div>
    </div>
    <div class="col text-end align-self-end p-0">
      <a :href="tourPostUrl" class="text-decoration-none text-primary">
        {{ $t('edit_details') }}
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useLocaleStore } from '@/stores/localeStore';

defineProps<{
  tourTitle: string,
  tourDate: string | null,
  params: { id?: string, lang?: string, date?: string, start_time?: string },
  tourPostUrl: string,
}>();

const { siteLanguages } = useLocaleStore();

</script>

<style scoped>
.tour-info-container {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1)
}
</style>
