<template>
  <div class="free-cancellation-info p-3 mb-3 rounded">
    <!-- PAD-2756: calculate date and refund amount based on cancellation rules-->
    <span>{{ $t('if_you_cancel_before', { date: '27 May', refund: '€90.00' }) }}</span>
  </div>

  <div
    class="payment-method-selection"
    v-if="checkoutOptions.paymentMethods.length > 0"
  >
    <!-- PAD-2756: check paymentConditions, if 1 rule, show this bit -->
    <!-- <div
      v-if="paymentConditions['full-flex'].rules.length == 1"
      class="free-cancellation-rule"
    >
      <img
        class="cancellation-icon"
        src="/wp-content/themes/bajabikes/images/cancellation.svg"
        alt="cancellation">
      <div class="cancellation-text bb-green">{{ cancellationText }}</div>
    </div> -->

    <!-- if more than 1 rule, show all payment Conditions -->

    <!-- if amountToPay > 0, show payment methods -->
    <div
      v-if="calculateGrossTotal() > 0"
      class="payment-method-selection d-flex flex-column gap-3"
    >
      <label
        v-for="(option, index) in checkoutOptions.paymentMethods"
        :for="`payment-option-${index}`"
        class="option d-flex align-items-center gap-2 border rounded p-2"
        :class="{
          [option.name]: true,
          'selected': option.name === checkoutRequest.payment_method
        }"
      >
        <div class="col-xs-11">
          <input
            @click="setPaymentMethod(option.name)"
            :id="`payment-option-${index}`"
            :checked="option.name === checkoutRequest.payment_method"
            name="paymentType"
            type="radio">
        </div>
        {{ option.description }}
        <span class="text-success">{{ isMostChosen(option.name) ? `(${$t('most_chosen')})` : '' }}</span>
      </label>
    </div>

    <div
      v-if="!checkoutOptions.termsAccepted && formSubmitted"
      class="text-danger mt-1"
    >
      <img class="me-1" src="@/assets/images/error.svg" alt="error" />
      <span class="fs-8">{{ $t('selected_payment_option') }}</span>
    </div>

  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useCheckoutStore } from '@/stores/checkoutStore';
import { useLocaleStore } from '@/stores/localeStore';

const { locale } = useLocaleStore();

const checkoutStore = useCheckoutStore();
const { checkoutOptions, checkoutRequest, formSubmitted } = storeToRefs(checkoutStore);
const { calculateGrossTotal } = checkoutStore;

const isMostChosen = (paymentMethod: string) => {
  return paymentMethod === 'iDeal' && locale !== 'da' || paymentMethod === 'credit_card' && locale === 'da';
}

const setPaymentMethod = (paymentMethod: string) => {
  checkoutRequest.value.payment_method = paymentMethod;
}
</script>

<style scoped>
.free-cancellation-info {
  background-color: #f2fbf5;
  color: #00ab3e;
}

.selected {
  border: 1px solid #2c508e !important;
  background-color: #f3f9fd !important;
}
</style>