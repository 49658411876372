<template>
  <TopBar />
  <main class="p-2 p-md-4">
    <div class="container my-4">
      <div>
        <h1>{{ $t('waiting_list.get_on_waiting_list') }}</h1>
      </div>

      <div class="row">
        <!-- Left Column: Activity Details + Waiting List Info -->
        <div class="col-lg-6 gx-5 gy-4">
          <h5 class="d-flex align-items-center mb-4">
            <span class="badge bg-primary me-2 rounded-pill">1</span>
            <span class="text-primary">{{ $t('activity_details') }}</span>
          </h5>

          <ProductInfo
            :tourTitle="tourTitle"
            :tourDate="tourDate"
            :params="params"
            :tourPostUrl="tourPostUrl"
          />
          <ProductSelection
            v-if="!productLoading"
            :minMaxParticipants="minMaxParticipants"
            :minMaxChildrenParticipants="minMaxChildrenParticipants"
            :prices="prices"
            :currency="productInfo?.currency_code"
            v-model:productOptions="productOptions"
            @update:gross-total="setGrossTotal"
          />

          <!-- Waiting List Info -->
          <div class="p-3 bg-light">
            <div class="row">
              <div class="col-1">
                <img src="@/assets/images/check-circle.svg" alt="Check" class="me-2" />
              </div>
              <div class="col-11">
                <span class="fs-6 fw-bold">{{ $t('waiting_list.get_on_waiting_list') }}</span>
                <br />
                <span class="fs-7">{{ $t('waiting_list.we_will_let_you_know') }}</span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-1">
                <img src="@/assets/images/check-circle.svg" alt="Check" class="me-2" />
              </div>
              <div class="col-11">
                <span class="fs-6 fw-bold">{{ $t('waiting_list.this_is_free') }}</span>
                <br />
                <span class="fs-7">
                  {{ $t('waiting_list.you_only_pay_if_reserve') }}
                  <br />
                  {{ $t('waiting_list.you_can_get_off_anytime') }}</span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-1">
                <img src="@/assets/images/check-circle.svg" alt="Check" class="me-2" />
              </div>
              <div class="col-11">
                <span class="fs-6 fw-bold">{{ $t('waiting_list.do_you_want_to_be_sure_spot') }}</span>
                <br />
                <a :href="props.tourPostUrl" class="fs-7 text-primary">{{ $t('waiting_list.check_available_spots') }}</a>
              </div>
            </div>
          </div>
        </div>

        <!-- Right Column: Contact Details -->
        <div class="col-lg-6 gx-5 gy-4">
          <h5 class="d-flex align-items-center mb-4">
            <span class="badge bg-primary me-2 rounded-pill">2</span>
            <span class="text-primary">{{ $t('contact_details') }}</span>
          </h5>
          <CustomerInfo
            :handleSubmit="handleSubmit"
            v-model:customer="customer"
            :isSubmitDisabled="waitingListLoading"
          />

          <div class="row checkout-features p-3 section">
            <div class="col-sm-12">
              <ul class="list-unstyled">
                <li
                  class="mb10"
                  v-for="item in props.checkoutFeatures"
                  :key="item"
                >
                  <span>{{ item.feature }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { onMounted, ref, reactive } from 'vue';
import { useDates } from '@/composables/useDates';
import { useLocaleStore } from '@/stores/localeStore';

import { WaitingList } from '@/types/waiting-list';

import ProductInfo from "@/components/ProductInfo.vue";
import ProductSelection from "@/components/ProductSelection.vue";
import CustomerInfo from "@/components/CustomerInfo.vue";
import TopBar from "@/components/TopBar.vue";

import { useProductInfo } from '@/composables/useProductInfo';
import { useWaitingList } from '@/composables/useWaitingList';
import { useProductStore } from '@/stores/productStore';
import { storeToRefs } from 'pinia';

const props = defineProps<{
  locale: string,
  checkoutFeatures: any,
  productId: string,
  successUrl: string,
  tourTitle: string,
  tourPostUrl: string,
}>();

const productOptions = defineModel('productOptions', { default: reactive({
  selectedPax: 1,
  selectedChildrenPax: 0
 })});
const customer = defineModel('customer', { default: reactive({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  confirmEmail: '',
 })});

const { formatDisplayDate } = useDates();
const { setLocale } = useLocaleStore();

const productStore = useProductStore();
const { fetchPrices } = productStore;
const { prices } = storeToRefs(productStore);
const { fetchProduct, productInfo, loading: productLoading } = useProductInfo();
const { createWaitingList, error, loading: waitingListLoading } = useWaitingList();

setLocale(props.locale);

const params = ref<{ id?: string; lang?: string; date?: string; start_time?: string }>({});
const tourDate = ref<string | null>(null);
const minMaxParticipants = ref({ min: 0, max: 0 });
const minMaxChildrenParticipants = ref({ min: 0, max: 0 });
const grossTotal = ref(0);

onMounted(async () => {
  const urlParams = new URLSearchParams(window.location.search);

  params.value = {
    id: urlParams.get('id') || '',
    lang: urlParams.get('lang') || '',
    date: urlParams.get('date') || '',
    start_time: urlParams.get('start_time') || ''
  };

  if (params.value.date) {
    tourDate.value = formatDisplayDate(new Date(params.value.date));
  }

  if (params.value.date && params.value.start_time) {
    await fetchPrices(props.productId.toString(), props.locale, params.value.date, params.value.start_time);

    setMinMaxParticipants(prices.value);
  }

  await fetchProduct(props.productId.toString());

  // set product's min pax
  productOptions.value.selectedPax = productInfo.value?.min_participants_amount || 1;
});

const handleSubmit = async () => {
  let body: WaitingList = {
    customer: {
      first_name: customer.value.firstName,
      last_name: customer.value.lastName,
    },
    customer_phone: customer.value.phone,
    email: customer.value.email,
    tour_date: params.value.date || '',
    tour_time: params.value.start_time || '',
    tour_language_code: params.value.lang || '',
    product: {
      product_id: parseInt(props.productId),
      product_title: props.tourTitle,
    },
    participants: productOptions.value.selectedPax,
    participants_child: productOptions.value.selectedChildrenPax,
    post_id: params.value.id || '',
    currency: {
      symbol: decodeHtmlEntity(productInfo.value?.currency_symbol || ''),
      code: productInfo.value?.currency_code || '',
    },
    metadata : {
      supplier_email: overrideEmail(productInfo.value?.supplier_email),
    }
  };

  const profitStr = body['currency'].code === 'EUR' ? 'gross_profit' : 'gross_profit_eur'; // looks weird but is correct order
  body = Object.assign(body, { [profitStr]: grossTotal.value });

  await createWaitingList(body);
  if (error.value) {
    console.error(`Error creating waiting list: ${error.value}`);
    return;
  }

  window.location.href = props.successUrl;
}

const overrideEmail = (email = '') => {
    const overrideEmail = import.meta.env.VITE_DEVELOPER_EMAIL_OVERRIDE;
    if (!overrideEmail) return email;

    const fuzzyEmail = email.replace(/[^a-zA-Z0-9 -]/g, '');

    // assumes override is it+bbb@bar.com pattern
    return overrideEmail.replace('bbb', fuzzyEmail);
  }

const decodeHtmlEntity = (entity = "€") => {
  let txt = document.createElement('textarea');
  txt.innerHTML = entity;
  return txt.value;
};

const setMinMaxParticipants = (prices: any) => {
  minMaxParticipants.value = {
    min: prices.prices.reduce((min: number, price: any) => Math.min(min, price.min_participants), prices.prices[0].min_participants),
    max: prices.prices.reduce((max: number, price: any) => Math.max(max, price.max_participants), prices.prices[0].max_participants),
  };

  if (prices.children_prices?.length > 0) {
    minMaxChildrenParticipants.value = {
      min: 0,
      max: prices.children_prices.reduce((max: number, price: any) => Math.max(max, price.max_participants), prices.children_prices[0].max_participants),
    };
  }
};

const setGrossTotal = (profit: number) => {
  grossTotal.value = profit;
};

</script>

<style scoped>
.bg-light {
  background-color: #f2fbf5 !important;
}

.checkout-features {
  li {
    background: url("/wp-content/themes/bajabikes2025/src/assets/images/green-tick-icon.png") no-repeat 0;
    padding-left: 20px;
    background-size: 12px;
  }
}
</style>
