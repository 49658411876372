import { ref, computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import type { ProductInfo } from '@/types/product';
import type { PaymentMethod } from '@/types/checkout';
import type { Attribute, PricesData } from '@/types/prices';
import { loadStripe } from '@stripe/stripe-js';

export const useProductStore = defineStore('productStore', () => {
  // State
  const productInfo = ref<ProductInfo | null>(null);
  const prices = ref<PricesData>({
    prices: [],
    children_prices: [],
  });
  const paymentMethods = ref<PaymentMethod[]>([]);
  const attributes = ref<Attribute[]>([]);
  // Loading states
  const isLoading = ref(true);
  const isPricesLoading = ref(false);
  const isPaymentMethodsLoading = ref(false);
  const isAttributePricesLoading = ref(false);
  // Error states
  const error = ref<string | null>(null);
  const pricesError = ref<string | null>(null);
  const paymentMethodsError = ref<string | null>(null);

  // Actions
  const fetchProduct = async (productId: string, language: string) => {
    isLoading.value = true;
    error.value = null;

    try {
      const response = await fetch(
        `${import.meta.env.VITE_PRODUCTS_API_URL}/${productId}?language=${language}`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) throw new Error('Failed to fetch product');
      const data = await response.json();
      productInfo.value = data.data;
    } catch (err) {
      error.value = err instanceof Error ? err.message : 'Failed to fetch product';
    } finally {
      isLoading.value = false;
    }
  };

  const fetchPrices = async (productId: string, language: string, date: string, time: string) => {
    isPricesLoading.value = true;
    pricesError.value = null;

    try {
      const response = await fetch(
        `${import.meta.env.VITE_INTEGRATION_API_URL}/prices/${productId}?language=${language}&date=${date}T${time}&raw=true`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) throw new Error('Failed to fetch prices');
      const data = await response.json();
      prices.value = data.data;
    } catch (err) {
      pricesError.value = err instanceof Error ? err.message : 'Failed to fetch prices';
    } finally {
      isPricesLoading.value = false;
    }
  };

  const fetchAttributePrices = async (productId: string, language: string) => {
    if (!productId) return;

    isAttributePricesLoading.value = true;
    error.value = null;

    const apiUrl = `${import.meta.env.VITE_INTEGRATION_API_URL}/attributes/${productId}?language=${language}`;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch attributes[${productId}]: ${response.statusText}`);
      }

      const data = await response.json();
      attributes.value = data.data ?? [];
    } catch (err) {
      error.value = err instanceof Error ? err.message : String(err);
      console.error(error);
    } finally {
      isAttributePricesLoading.value = false;
    }
  }

  const fetchPaymentMethods = async () => {
    isPaymentMethodsLoading.value = true;
    paymentMethodsError.value = null;

    try {
      const response = await fetch(`${import.meta.env.VITE_PRODUCTS_API_URL}/payment-methods`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) throw new Error('Failed to fetch payment methods');
      paymentMethods.value = (await response.json()).data;

      // Check for Google/Apple Pay availability
      await checkMobilePayAvailability();
    } catch (err) {
      paymentMethodsError.value = err instanceof Error ? err.message : 'Failed to fetch payment methods';
    } finally {
      isPaymentMethodsLoading.value = false;
    }
  };

  const checkMobilePayAvailability = async () => {
    try {
      const stripe = await loadStripe(import.meta.env.VITE_STRIPE_KEY);
      if (!stripe) return;

      const paymentRequest = stripe.paymentRequest({
        country: 'ES',
        currency: 'eur',
        total: {
          label: 'Total',
          amount: 100,
        },
        requestPayerName: false,
        requestPayerEmail: false,
        requestShipping: false,
      });

      const result = await paymentRequest.canMakePayment();
      console.log("@@@ gpay wallet check", result);

      if (result?.googlePay) {
        paymentMethods.value.push({
          id: 4,
          name: 'google_pay',
          description: 'Google Pay'
        });
      }

      if (result?.applePay) {
        paymentMethods.value.push({
          id: 5,
          name: 'apple_pay',
          description: 'Apple Pay'
        });
      }
    } catch (error) {
      console.error('Error checking mobile pay availability:', error);
    }
  };

  // Getters
  const isPrepaid = computed(() => productInfo.value?.is_prepaid ?? false);
  const minParticipants = computed(() => productInfo.value?.min_participants_amount ?? 1);
  const bookingFee = computed(() => productInfo.value?.apply_booking_fee && productInfo.value?.booking_fee ? productInfo.value?.booking_fee : 0);

  return {
    // State
    productInfo,
    prices,
    paymentMethods,
    isLoading,
    isPricesLoading,
    isPaymentMethodsLoading,
    isAttributePricesLoading,
    error,
    pricesError,
    paymentMethodsError,

    // Actions
    fetchProduct,
    fetchPrices,
    fetchPaymentMethods,
    fetchAttributePrices,

    // Getters
    isPrepaid,
    minParticipants,
    bookingFee,
    attributes,
  };
});