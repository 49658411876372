import { ref } from 'vue';

import { ProductInfo } from '@/types/product';
import { useLocaleStore } from '@/stores/localeStore';
import { PaymentMethod } from '@/types/checkout';

export function useProductInfo() {
  const loading = ref(true);
  const error = ref<string | null>(null);
  const paymentMethodsLoading = ref(true);
  const paymentMethodsError = ref<string | null>(null);

  const productInfo = ref<ProductInfo>();
  const paymentMethods = ref<PaymentMethod[]>([]);

  const fetchProduct = async (productId: string) => {
    if (!productId) return;

    const localeStore = useLocaleStore();
    const language = localeStore.locale;

    const apiUrl = `${import.meta.env.VITE_PRODUCTS_API_URL}/${productId}?language=${language}`;
    loading.value = true;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch product[${productId}]: ${response.statusText}`);
      }

      const data = await response.json();
      productInfo.value = data.data;
    } catch (err) {
      error.value = err instanceof Error ? err.message : String(err);
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  const fetchPaymentMethods = async () => {
    const apiUrl = `${import.meta.env.VITE_PRODUCTS_API_URL}/payment-methods`;

    loading.value = true;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch payment methods: ${response.statusText}`);
      }

      const data = await response.json();
      paymentMethods.value = data.data;
    } catch (err) {
      error.value = err instanceof Error ? err.message : String(err);
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    error,
    fetchProduct,
    productInfo,
    paymentMethodsLoading,
    paymentMethodsError,
    fetchPaymentMethods,
    paymentMethods
  };
}