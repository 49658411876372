<template>
  <TopBar />
  <main class="p-2 p-md-4">
    <div class="container my-4">
      <div class="row g-4">
        <div class="col-12 col-xxl-6 px-xxl-5">
          <div>
            <h1 class="fs-1 fw-bold mb-3">{{ title }}</h1>
            <span class="fs-6">{{ subtitle }}</span>
            <span v-if="reviews[productId]">
              <ReviewStars :average="reviews[productId]?.average" :total="reviews[productId]?.total" />
            </span>
          </div>

          <div class="">
            <ImageCarousel :images="gallery.images" />
          </div>
        </div>

        <div class="col-12 col-xxl-6 border border-2 rounded-4 shadow p-4">
          <CalendarWithTimeslots
            v-if="!isBikeRental"
            :postId="postId"
            :productId="productId"
            :price="price"
            :priceChildren="priceChildren"
            :duration="duration"
            :cityPageOfProduct="cityPageOfProduct"
            :cityPageOfProductUrl="cityPageOfProductUrl"
            :cityName="cityName"
            :productsOfCity="productsOfCity"
            :rentalProductOfCity="rentalProductOfCity"
          />

          <BikeRentalSelection
            v-else
            :productId="productId"
            :price="price"
            :priceChildren="priceChildren"
            :duration="duration"
          />
        </div>
      </div>

      <!-- Tabs Section -->
      <div class="mt-4">
        <ul class="d-flex justify-content-center fs-7 nav nav-tabs gap-1 pb-1">
          <li class="nav-item flex-fill rounded-top-3 gap-2" v-for="(tab, index) in tabs" :key="index">
            <button
              class="nav-link btn w-100"
              @click="activeTab = index; scrollToElement(`product-content-${index + 1}`)"
            >
              {{ tab.name }}
            </button>
          </li>
        </ul>
        <div class="tab-content mt-3">
          <div
            class="tab-pane fade show active"
          >
            <div id="product-content-1" class="mb-3" v-html="tabContent1"></div>
          </div>
          <div
            class="tab-pane pane-2 fade show active d-flex"
            style="flex-wrap: wrap;"
          >
            <div id="product-content-2" class="mb-3 col-xs-6" v-html="tabContent2"></div>
            <div id="product-content-3" class="mb-3 col-xs-6">
              <div v-html="tabContent3"></div>
              <div v-if="address" class="map">
                <div class="address">
                    <strong>{{ address }}</strong>
                </div>

                <iframe class="map-top" :src="mapSrc" ></iframe>

                <div class="direction">
                    <a :href="`//maps.google.com/maps?daddr=${address}`" target="_blank">{{ $t('show_route_to_accommodation') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Reviews -->
      <div class="text-center my-5 py-5">
        <h3 class="fs-4 fw-bold mb-3">This is what customers say about this tour (Coming soon)</h3>
        <h4>848 rate this tour with 4.5 stars</h4>
        <div class="d-flex gap-3 justify-content-center">
          <span>Shared Reviews (Coming soon)</span>
        </div>
      </div>

      <div
        v-if="favoriteTours?.tours?.length > 0"
        class="my-4"
      >
        <h3 class="text-center fw-bold mb-3">{{ favoriteTours.title }}</h3>
        <h4 class="text-center">{{ favoriteTours.subTitle }}</h4>

        <TopBikeTours :items="favoriteTours.tours"/>
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useEkomiReviews } from "@/composables/useEkomiReviews";
import { useLocaleStore } from "@/stores/localeStore";

import TopBar from "@/components/TopBar.vue";
import ReviewStars from "@/components/ReviewStars.vue";
import ImageCarousel from "@/components/ImageCarousel.vue";
import CalendarWithTimeslots from "@/components/CalendarWithTimeslots.vue";
import BikeRentalSelection from "@/components/BikeRentalSelection.vue";
import TopBikeTours from "@/components/TopBikeTours.vue";
import type { TourData } from "@/types/top-bike-tour";

const props = defineProps<{
  locale: string,
  postId: number,
  productId: string,
  price: string,
  priceChildren: string,
  duration: string,
  title: string,
  subtitle: string,
  tabTitle1: string,
  tabTitle2: string,
  tabTitle3: string,
  tabContent1: string,
  tabContent2: string,
  tabContent3: string,
  address: string,
  gallery: any,
  isBikeRental: boolean,
  cityPageOfProduct: any,
  cityPageOfProductUrl: string,
  cityName: string,
  productsOfCity: any,
  rentalProductOfCity: any,
  favoriteTours: {
    title: string,
    subTitle: string,
    tours: TourData[],
  }
}>();

const localestore = useLocaleStore();
localestore.setLocale(props.locale);

const { reviews, fetchReviews } = useEkomiReviews();
fetchReviews([props.productId]);

const activeTab = ref(0);
const tabs = ref([
  { name: props.tabTitle1 },
  { name: props.tabTitle2 },
  { name: props.tabTitle3 },
]);

const scrollToElement = (targetElementId: string) => {
  const element = document.getElementById(targetElementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

const mapSrc = computed(() =>
  `https://www.google.com/maps/embed/v1/place?key=${import.meta.env.VITE_GOOGLE_MAP_API_KEY}&q=${props.address}&zoom=14`
);
</script>

<style scoped>
.nav-tabs {
  border-bottom: 1px solid #E8F3FA;
  .nav-item {
    background-color: #E8F3FA;
  }

  .nav-link {
    color: #2C508E;
    font-weight: 600;
  }

  .nav-link:hover {
    color: #495057;
    background-color: #fff;
  }
}

.tab-pane {
  &::v-deep(h2) {
    padding: 1rem 0;
  }

  &::v-deep(ul) {
    list-style: none;
    padding-left: 0;

    li::before {
      content: '\2713';
      margin-right: 8px;
    }
  }
}

#product-content-1 {
  &::v-deep(strong) {
    width: 100%;
  }

  &::v-deep(ul:nth-of-type(2)) {
    columns: 2;
  }

  &::v-deep(h3) {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
}

#product-content-2 {
  &::v-deep(i.fa-stack-2x) {
    color: #2C508E;
  }

  &::v-deep(.fa-ul) {
    li::before {
      content: '';
    }
  }
}

#product-content-3 {
  &::v-deep(i.fa-stack-2x) {
    color: #2C508E;
  }

  &::v-deep(.fa-ul) {
    li::before {
      content: '';
    }
  }
}

@media (min-width: 768px) {
  .pane-2 {
    gap: 2rem;
  }

  #product-content-2, #product-content-3 {
    flex: 1;
    width: 50%;
  }
}
</style>