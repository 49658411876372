<template>
  <div class="row g-3">
    <div class="col-md-6">
      <input
        type="text"
        class="form-control"
        :placeholder="$t('first_name')"
        v-model.trim="customer.firstName"
        @blur="validate('firstName')"
        :class="{
          'is-invalid': errors.firstName,
          'is-valid': !errors.firstName && customer.firstName
        }"
      />
      <div v-if="errors.firstName" class="text-danger mt-1">
        <img class="me-1" src="@/assets/images/error.svg" alt="error" />
        <span class="fs-8">{{ $t('first_name_error_message') }}</span>
      </div>
    </div>
    <div class="col-md-6">
      <input
        type="text"
        class="form-control"
        :placeholder="$t('last_name')"
        v-model.trim="customer.lastName"
        @blur="validate('lastName')"
        :class="{
          'is-invalid': errors.lastName,
          'is-valid': !errors.lastName && customer.lastName
        }"
      />
      <div v-if="errors.lastName" class="text-danger mt-1">
        <img class="me-1" src="@/assets/images/error.svg" alt="error" />
        <span class="fs-8">{{ $t('last_name_error_message') }}</span>
      </div>
    </div>

    <div class="col-12">
      <input
        type="email"
        class="form-control"
        :placeholder="$t('email')"
        v-model.trim="customer.email"
        @blur="validate('email')"
        :class="{
          'is-invalid': errors.email,
          'is-valid': !errors.email && customer.email
        }"
      />
      <div v-if="errors.email" class="text-danger mt-1">
        <img class="me-1" src="@/assets/images/error.svg" alt="error" />
        <span class="fs-8">{{ $t('email_error_message') }}</span>
      </div>
    </div>

    <div class="col-12">
      <VueTelInput
        v-bind="phoneOptions"
        @blur="handlePhoneBlur"
        @validate="handlePhoneValidation"
        class="form-control d-flex phone-input"
        :class="{
          'is-invalid': errors.phone,
          'is-valid': !errors.phone && customer.phone
        }"
      ></VueTelInput>
      <div v-if="errors.phone" class="text-danger mt-1">
        <img class="me-1" src="@/assets/images/error.svg" alt="error" />
        <span class="fs-8">{{ $t('phone_error_message') }}</span>
      </div>
    </div>

    <div class="col-12">
      <input
        type="email"
        class="form-control"
        :placeholder="$t('confirm_email')"
        v-model.trim="customer.confirmEmail"
        @blur="handleEmailMatch"
        :class="{
          'is-invalid': errors.emailConfirm,
          'is-valid': !errors.emailConfirm && customer.confirmEmail
        }"
      />
      <div v-if="errors.emailConfirm" class="text-danger mt-1">
        <img class="me-1" src="@/assets/images/error.svg" alt="error" />
        <span class="fs-8">{{ $t('email_mismatch_error_message') }}</span>
      </div>
    </div>

    <div class="col mt-2">
      <input
        class="me-2"
        type="checkbox"
        id="terms"
        v-model="acceptedTerms"
      />
      <label class="terms-label flex-grow-1" for="terms">
        {{ $t('terms_and_conditions_1') }}
        <a href="/general-terms-and-conditions" class="text-primary">{{ $t('terms_and_conditions_2') }}</a>
      </label>
      <div v-if="!acceptedTerms && formSubmitted" class="text-danger mt-1">
        <img class="me-1" src="@/assets/images/error.svg" alt="error" />
        <span class="fs-8">{{ $t('terms_error_message') }}</span>
      </div>
    </div>
  </div>
  <button
    class="btn btn-success w-100 mt-3"
    :class="{'disabled': (!isValid && formSubmitted) || props.isSubmitDisabled}"
    @click="validateAndhandleSubmit"
  >
    {{ $t('waiting_list.inform_me_when_spots_become_available') }}
  </button>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

const props = defineProps<{
  handleSubmit: () => void,
  isSubmitDisabled: boolean,
}>();

const customer = defineModel('customer', { default: {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  confirmEmail: '',
}});

const formSubmitted = ref(false);
const acceptedTerms = ref(false);
const isPhoneValid = ref(false);

const errors = ref({
  firstName: false,
  lastName: false,
  email: false,
  phone: false,
  emailConfirm: false,
});

const isValid = computed(() => {
  return !errors.value.firstName
    && !errors.value.lastName
    && !errors.value.email
    && !errors.value.phone
    && !errors.value.emailConfirm
    && acceptedTerms.value;
});

const phoneOptions = {
  mode: "international",
  preferredCountries: ["nl", "de", "da", "gb", "us"],
  autoDefaultCountry: true,
  validCharactersOnly: true,
  dropdownOptions: {
    showFlags: true,
    showDialCodeInSelection: true,
    showSearchBox: false,
  },
  inputOptions: {
    placeholder: "1234567890",
  },
};

const handlePhoneValidation = (phoneObject: any) => {
  isPhoneValid.value = phoneObject.valid;

  if (phoneObject.valid) {
    customer.value.phone = phoneObject.number;
  }
};

const handlePhoneBlur = () => {
  errors.value.phone = !isPhoneValid.value;
};

const handleEmailMatch = () => {
  errors.value.emailConfirm = customer.value.email !== customer.value.confirmEmail;
};

type CustomerField = 'firstName' | 'lastName' | 'email' | 'phone';
const validate = (fieldName: CustomerField) => {
  errors.value[fieldName] = !customer.value[fieldName];
};

const validateAndhandleSubmit = () => {
  formSubmitted.value = true;

  ['firstName', 'lastName', 'email', 'phone'].forEach((fieldName) => {
    validate(fieldName as CustomerField);
  });

  if (isValid.value) props.handleSubmit();
};

</script>

<!-- not scoped to apply to phone number dropdown -->
<style>
.vti__dropdown-list {
  font-weight: 100;

  .vti__dropdown-item.last-preferred {
    border-bottom: none !important;
    border-top: 1px solid #cacaca !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.form-control.vue-tel-input.is-valid {
  input {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

</style>

<style scoped>
.phone-input {
  padding: 0 !important;
}

.form-control.is-invalid {
  background-image: none !important;
}

.terms-label {
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
}
</style>