<template>
  <main class="p-4 p-md-5">
    <div class="container mt-4 main-content" v-html="mainContent"></div>
  </main>
</template>

<script lang="ts" setup>

defineProps<{
  mainContent: string,
}>();

</script>

<style scoped>
main {
  min-height: 75vh;
}

.main-content {
  &::v-deep(h1) {
    color: #2d508e;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }

  &::v-deep(p) {
    color: #474747;
  }
}
</style>
