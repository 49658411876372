<template>
  <TopBar :hasMenu="false" />
  <main class="p-2 p-md-4">
    <div class="container my-2">
      <div class="row">
        <!-- Left Column: Activity Details -->
        <div
          class="col-lg-6 gy-4 bg-white rounded-3 p-3"
          :class="{
            'payment-in-progress': checkoutOptions.isPaymentInProgress
          }"
        >
          <h5 class="d-flex align-items-center mb-4">
            <span class="badge bg-primary me-2 rounded-pill">1</span>
            <span class="text-primary">{{ $t('activity_details') }}</span>
          </h5>

          <ProductInfo
            :tourTitle="tourTitle"
            :tourDate="tourDate"
            :params="params"
            :tourPostUrl="tourPostUrl"
          />
          <ProductSelection
            v-if="!productLoading"
            :prices="checkoutOptions.prices"
            :currency="productInfo?.currency_code"
          />
        </div>

        <!-- Right Column: Contact Details / payment -->
        <div
          class="col-lg-6 gy-4 bg-white rounded-3 p-3"
        >
          <div
            :class="{
              'payment-in-progress': checkoutOptions.isPaymentInProgress
            }"
          >
            <h5 class="d-flex align-items-center mb-4">
              <span class="badge bg-primary me-2 rounded-pill">2</span>
              <span class="text-primary">{{ $t('contact_details') }}</span>
            </h5>

            <CustomerInfoBooking
              :handleSubmit="handleSubmit"
              :isSubmitDisabled="false"
            />
          </div>

          <div
            :class="{
              'payment-in-progress': checkoutOptions.isPaymentInProgress
            }"
          >
            <div
              class="col mt-4"
              v-if="productInfo?.is_prepaid"
            >
              <h5 class="d-flex align-items-center mb-4">
                <span class="badge bg-primary me-2 rounded-pill">3</span>
                <span class="text-primary">{{ $t('book_your_spot_cancel_free') }}</span>
              </h5>
              <PaymentMethodSelection />
            </div>

            <div class="col mt-2">
              <input
                class="me-2"
                type="checkbox"
                id="terms"
                v-model="checkoutOptions.termsAccepted"
              />
              <label class="terms-label flex-grow-1" for="terms">
                {{ $t('terms_and_conditions_1') }}
                <a href="/general-terms-and-conditions" class="text-primary">{{ $t('terms_and_conditions_2') }}</a>
              </label>
            </div>

            <div class="col mt-2">
              <span class="fw-bold d-flex justify-content-end">
                {{ $t("total") }}
                {{ formatCurrency(calculateGrossTotal(), productInfo?.currency_code) }}
              </span>
            </div>

            <div class="col mt-2">
              <div v-if="!checkoutOptions.termsAccepted && formSubmitted" class="text-danger mt-1">
                <img class="me-1" src="@/assets/images/error.svg" alt="error" />
                <span class="fs-8">{{ $t('terms_error_message') }}</span>
              </div>
              <button
                class="btn btn-success w-100 mt-3"
                :class="{
                  'disabled': checkoutOptions.isSubmitDisabled
                }"
                @click="handleSubmit"
              >
                {{ $t('book_now') }}
              </button>
            </div>
          </div>

          <div class="col mt-2">
            <PaymentDetails
              v-if="!checkoutStore.isWallet && stripeToken && checkoutOptions.bookingToken"
              :clientSecret="stripeToken"
              :bookingToken="checkoutOptions.bookingToken"
            />
          </div>
        </div>

      </div>
    </div>

    <PaymentWalletDetails
      v-if="checkoutStore.isWallet && stripeToken && checkoutOptions.bookingToken"
      :clientSecret="stripeToken"
      :bookingToken="checkoutOptions.bookingToken"
      @paymentSuccess="handlePaymentSuccess"
      @paymentError="handlePaymentError"
    />
  </main>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';

import { useLocaleStore } from '@/stores/localeStore';
import { useCheckoutStore } from '@/stores/checkoutStore';
import { useProductSelectionsStore } from '@/stores/productSelectionsStore';
import { useProductStore } from '@/stores/productStore';

import { useDates } from '@/composables/useDates';
import { formatCurrency } from '@/helpers/parseCurrency';
import TopBar from '../components/TopBar.vue';
import ProductInfo from '../components/ProductInfo.vue';
import ProductSelection from '../components/ProductSelection.vue';
import CustomerInfoBooking from '../components/CustomerInfoBooking.vue';
import PaymentMethodSelection from '../components/PaymentMethodSelection.vue';
import PaymentDetails from '../components/PaymentDetails.vue';
import PaymentWalletDetails from '../components/PaymentWalletDetails.vue';
import { cleanParams } from '@/helpers/cleanParams';

const props = defineProps<{
  locale: string,
  successUrl: string,
  cityName: string,
  tourTitle: string,
  tourPostUrl: string,
  checkoutFeatures: any,
  productId: number,
  referrer: string,
  checkoutPage: string,
  reminderNonce: string,
  stripeKey: string,
}>();

const { setLocale } = useLocaleStore();
setLocale(props.locale);

const productStore = useProductStore();
const { fetchProduct, fetchPaymentMethods, fetchPrices, fetchAttributePrices } = productStore;
const { attributes, productInfo, isLoading: productLoading, paymentMethods, prices } = storeToRefs(productStore);

const { productOptions } = storeToRefs(useProductSelectionsStore());

const checkoutStore = useCheckoutStore();
const {
  checkoutRequest,
  checkoutOptions,
  formSubmitted,
  stripeToken
} = storeToRefs(checkoutStore);
const {
  handleSubmit: handleCheckoutSubmit,
  validateForm,
  isOrderPaymentConfirmed,
  calculateGrossTotal,
} = checkoutStore;

const params = ref<{
  id?: string;
  lang?: string;
  date?: string;
  start_time?: string;
  booking_token?: string;
}>({});
const tourDate = ref<string | null>(null);
const { formatDisplayDate } = useDates();

onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get('booking_token')) {
    checkoutOptions.value.bookingToken = urlParams.get('booking_token') || '';
    checkoutOptions.value.isSubmitDisabled = true;
    checkoutOptions.value.isPaymentInProgress = true;
    isOrderPaymentConfirmed();
  }
});

onMounted(async () => {
  const urlParams = new URLSearchParams(window.location.search);

  console.log("PAD-2752: error modal if missing params", urlParams);

  params.value = {
    id: urlParams.get('id') || '',
    lang: urlParams.get('lang') || '',
    date: urlParams.get('date') || '',
    start_time: urlParams.get('start_time') || ''
  };

  if (params.value.date) {
    tourDate.value = formatDisplayDate(new Date(params.value.date));
  }

  if (params.value.date && params.value.start_time) {
    await fetchPrices(props.productId.toString(), props.locale, params.value.date, params.value.start_time);
    checkoutOptions.value.prices = prices.value;

    console.log("PAD-2752: error modal if participants error");

    setMinMaxParticipants(prices.value);
  }

  checkoutRequest.value.product_id = props.productId;
  checkoutRequest.value.tour_date = params.value.date || '';
  checkoutRequest.value.tour_time = params.value.start_time || '';
  checkoutRequest.value.tour_language = params.value.lang || '';
  checkoutOptions.value.successUrl = props.successUrl;
  checkoutOptions.value.checkoutPage = props.checkoutPage;

  await fetchProduct(props.productId.toString(), props.locale);
  // set product's min pax
  productOptions.value.selectedPax = productInfo.value?.min_participants_amount || 1;

  await fetchAttributePrices(props.productId.toString(), props.locale);
  productOptions.value.attributes = attributes.value;

  if (productInfo.value?.is_prepaid) {
    checkoutOptions.value.isPrepaid = true;
    await fetchPaymentMethods();

    console.log("PAD-2754: mobilePayment (google/apple)", paymentMethods);

    checkoutOptions.value.paymentMethods = paymentMethods.value;
  } else {
    checkoutRequest.value.payment_method = 'cash';
  }
});

 const setMinMaxParticipants = (prices: any) => {
  productOptions.value.minMaxParticipants = {
    min: prices.prices.reduce((min: number, price: any) => Math.min(min, price.min_participants), prices.prices[0].min_participants),
    max: prices.prices.reduce((max: number, price: any) => Math.max(max, price.max_participants), prices.prices[0].max_participants),
  };

  if (prices.children_prices?.length > 0) {
    productOptions.value.minMaxChildrenParticipants = {
      min: 0,
      max: prices.children_prices.reduce((max: number, price: any) => Math.max(max, price.max_participants), prices.children_prices[0].max_participants),
    };
  }
};

const handleSubmit = () => {
  console.log('handleSubmit');
  validateForm();
  handleCheckoutSubmit();
};

const handlePaymentSuccess = () => {
  console.log('handlePaymentSuccess');
  // TODO: show modal progress bar
  // redirect to booking confirmation page
  // window.location.href = `${window.location.origin}/order/complete?token=${checkoutOptions.value.bookingToken}`;
  window.location.href = `${cleanParams(window.location.href, ['booking_token', 'livemode', 'client_secret', 'source', 'redirect_status'])}&booking_token=${checkoutOptions.value.bookingToken}`;
};

const handlePaymentError = (error: string) => {
  console.log('Stripe payment error', error);
  // TODO: show error modal
};

</script>

<style scoped>
main {
  background-color: #f9f9f9;
}

.payment-in-progress {
  opacity: 0.3;
  pointer-events: none;
}
</style>