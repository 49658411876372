<template>
  <div class="tour-selection-container mt-3">
    <div class="row mt-3 align-items-center">
      <div class="col-3 text-start nowrap">
        <label class="fw-bold">
          {{ productOptions.minMaxChildrenParticipants.max > 0 ? $t('adults') : $t('participants') }}
        </label>
      </div>
      <div class="col-2 text-end">
        <select
          class="form-select"
          v-model="productOptions.selectedPax"
        >
          <option
            v-for="participant in productOptions.minMaxParticipants.max"
            :key="participant"
            :value="participant"
          >
            {{ participant }}
          </option>
        </select>
      </div>
      <div class="col text-end"></div>
      <div class="col-2 text-end">
        {{ productOptions.selectedPax }} x {{ formatCurrency(getPrice(prices.prices, productOptions.selectedPax), props.currency) }}
      </div>
      <div class="col-2 text-end fw-bold">
        {{ formatCurrency(getTotalLineItemPrice(prices.prices, productOptions.selectedPax), props.currency) }}
      </div>
    </div>

    <div
      class="row mt-3 align-items-center"
      v-if="productOptions.minMaxChildrenParticipants.max > 0"
    >
      <div class="col-3 text-start nowrap">
        <label class="fw-bold">
          {{ $t('children') }}
        </label>
      </div>
      <div class="col-2 text-end">
        <select
          class="form-select"
          v-model="productOptions.selectedChildrenPax"
        >
          <option
            v-for="(_, idx) in (productOptions.minMaxChildrenParticipants.max + 1)"
            :key="idx"
            :value="idx"
          >
            {{ idx }}
          </option>
        </select>
      </div>
      <div class="col-3 text-end">
        <span v-if="productOptions.selectedChildrenPax > 0">
          <span class="text-decoration-line-through">
            {{ formatCurrency(getPrice(prices.prices, productOptions.selectedPax), props.currency) }}
          </span>
          <span class="text-success">
            -{{
              getChildDiscountPercent(
                getPrice(prices.prices, productOptions.selectedPax),
                getPrice(prices.children_prices, productOptions.selectedChildrenPax)
              )
            }}%
          </span>
        </span>
      </div>
      <div class="col-2 text-end">
        <span v-if="productOptions.selectedChildrenPax > 0">
          {{ productOptions.selectedChildrenPax }} x {{ formatCurrency(getPrice(prices.children_prices, productOptions.selectedChildrenPax), props.currency) }}
        </span>
      </div>
      <div class="col-2 text-end fw-bold">
        <span v-if="productOptions.selectedChildrenPax > 0">
          {{ formatCurrency(getTotalLineItemPrice(prices.children_prices, productOptions.selectedChildrenPax), props.currency) }}
        </span>
      </div>
    </div>

    <h6 v-if="productOptions.attributes.length > 0" class="fw-bold border-top pt-4 mt-3">{{ $t('add_attributes') }}</h6>

    <div
      class="row mt-3 align-items-center"
      v-for="attribute in productOptions.attributes"
      :key="attribute.id"
    >
      <div class="col-3 text-start nowrap">
        <label class="fs-7">
          {{ attribute.name }}
        </label>
      </div>
      <div class="col-2 text-end">
        <select
          class="form-select col-auto"
          v-model="selectedAttributesWithDefault[attribute.id]"
        >
          <option
            v-for="(_, idx) in attribute.max_amount + 1"
            :key="idx"
            :value="idx"
          >
            {{ idx }}
          </option>
        </select>
      </div>
      <div class="col text-end"></div>
      <div
        class="col-2 text-end"
        v-if="productOptions.selectedAttributes[attribute.id] > 0"
      >
        {{ productOptions.selectedAttributes[attribute.id] }}x
        {{ formatCurrency(getAttributePrice(attribute.prices, productOptions.selectedAttributes[attribute.id]), props.currency) }}
      </div>
      <div
        class="col-2 text-end fw-bold"
        v-if="productOptions.selectedAttributes[attribute.id] > 0"
      >
        {{ formatCurrency(
            getTotalLineItemAttributePrice(
              attribute.prices,
              productOptions.selectedAttributes[attribute.id]
            ),
            props.currency
        ) }}
      </div>
    </div>
  </div>

  <div class="row mt-3 align-items-center border-top pt-3">
    <div class="d-flex justify-content-between p-2">
      <span>{{  $t("booking_fee") }}</span>
      <span>{{ formatCurrency(bookingFee, props.currency) }}</span>
    </div>
    <h6 class="fw-bold d-flex justify-content-between">
      <span>{{ $t("total") }}</span>
      <span>{{ formatCurrency(calculateGrossTotal(), props.currency) }}</span>
    </h6>
    <div v-if="formSubmitted && productOptions.selectedPax === 0" class="text-danger mt-1">
      <img class="me-1" src="@/assets/images/error.svg" alt="error" />
      <span class="fs-8">{{ $t('select_at_least_one_participant') }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';

import { useProductSelectionsStore } from '@/stores/productSelectionsStore';
import { useCheckoutStore } from '@/stores/checkoutStore';
import { useProductStore } from '@/stores/productStore';
import { formatCurrency } from '@/helpers/parseCurrency';
import { PricesData } from '@/types/prices';

const emit = defineEmits(['update:grossTotal']);

const props = defineProps<{
  currency: string | undefined;
  prices: PricesData;
}>();

const productSelectionsStore = useProductSelectionsStore();
const { productOptions } = storeToRefs(productSelectionsStore);

const { bookingFee } = storeToRefs(useProductStore());

const checkoutStore = useCheckoutStore();
const { formSubmitted } = storeToRefs(checkoutStore);
const {
  calculateGrossTotal,
  updateAmountToPay,
  getTotalLineItemPrice,
  getPrice,
  getAttributePrice,
  getTotalLineItemAttributePrice
} = checkoutStore;

// Watch for changes in selections and update amountToPay
watch(
  [
    () => productOptions.value.selectedPax,
    () => productOptions.value.selectedChildrenPax,
    () => productOptions.value.selectedAttributes
  ],
  () => {
    const total = calculateGrossTotal();
    emit('update:grossTotal', total);
    updateAmountToPay();
  },
  { deep: true }
);

// set default value to 0 if attribute is not selected
const selectedAttributesWithDefault = computed(() => {
  return new Proxy(productOptions.value.selectedAttributes, {
    get(target, key) {
      return target[key as string] ?? 0;
    }
  });
});

const getChildDiscountPercent = (price: number, childPrice: number) => {
  return Math.round(((price - childPrice) / price) * 100);
}

onMounted(() => {
  updateAmountToPay();
});
</script>

<style scoped>
select {
  width: 75px;
  font-size: 15px;
}
</style>
