import { createApp }      from 'vue';
import { createPinia }    from 'pinia';
import { i18n }           from '@/plugins/i18n';
import { useLocaleStore } from '@/stores/localeStore';
import Icon               from "@/components/Icon.vue";
import FontAwesomeIcon    from "@/plugins/fontawesome";

// Import layouts
import CityPage        from '@/layouts/CityPage.vue';
import CheckoutPage from '@/layouts/CheckoutPage.vue';
import BookingConfirmationPage from '@/layouts/BookingConfirmationPage.vue';
import GeneralPage     from '@/layouts/GeneralPage.vue';
import GiftCardPage    from '@/layouts/GiftCardPage.vue';
import HomePage        from '@/layouts/HomePage.vue';
import Index           from '@/layouts/Index.vue';
import SearchPage      from '@/layouts/SearchPage.vue';
import TheFooter       from '@/components/TheFooter.vue';
import TourPage        from '@/layouts/TourPage.vue';
import WaitingListPage from '@/layouts/WaitingListPage.vue';
import NotFoundPage    from '@/layouts/NotFoundPage.vue';
import SuccessPage     from '@/layouts/SuccessPage.vue';
import FeedbackPage     from '@/layouts/FeedbackPage.vue';
import MobileChatPage   from '@/layouts/MobileChatPage.vue';
import MobileNumberPage from '@/layouts/MobileNumberPage.vue';

import ErrorModal      from '@/components/ErrorModal.vue';

const pinia = createPinia();

// Map WordPress template slugs to layouts
const layoutMap: Record<string, any> = {
  '#index-page'       : Index,
  '#city-page'        : CityPage,
  '#checkout-page'    : CheckoutPage,
  '#confirmation-page': BookingConfirmationPage,
  '#front-page'       : HomePage,
  '#gift-card-page'   : GiftCardPage,
  '#search-page'      : SearchPage,
  '#tour-page'        : TourPage,
  '#waiting-list-page': WaitingListPage,
  '#general-page'     : GeneralPage,
  '#footer'           : TheFooter,
  '#not-found-page'   : NotFoundPage,
  '#success-page'     : SuccessPage,
  '#mobile-number-page': MobileNumberPage,
  '#feedback-page'     : FeedbackPage,
  '#mobile-chat-page'  : MobileChatPage,
};

// Create and configure the Vue app instance
const createVueApp = (component: any, props = {}) => {
  const localeStore = useLocaleStore(pinia);
  i18n.global.locale.value = localeStore.locale as 'nl' | 'en' | 'da' | 'de';

  const app = createApp(component, props);
  app.use(pinia);
  app.use(i18n);
  app.component('Icon', Icon as any);
  app.component('font-awesome-icon', FontAwesomeIcon as any);
  return app;
};

// Mount the appropriate layout based on the selector
export const mountLayout = () => {
  const elements = document.querySelectorAll('[data-layout]');

  // Create a global ErrorModal instance
  const errorModalEl = document.createElement('div');
  document.body.appendChild(errorModalEl);
  createVueApp(ErrorModal).mount(errorModalEl);

  elements.forEach((el) => {
    const layoutKey = el.getAttribute('data-layout');
    const layout = layoutMap[layoutKey || ''];

    if (layout) {
      const layoutJson = el.getAttribute('data-json');
      const props = layoutJson ? JSON.parse(layoutJson) : {};

      createVueApp(layout, props).mount(el);
    }
  });
};