<template>
  <form class="pt-3" @submit.prevent="handleSubmit">
    <div id="payment-element"></div>
    <button
      class="btn btn-success w-100 mt-4"
      type="submit"
      :class="{
        'disabled': isLoading
      }"
    >
      {{ $t('confirm_and_pay') }}
    </button>
  </form>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { loadStripe, Stripe, StripePaymentElement } from '@stripe/stripe-js';

import { cleanParams } from '@/helpers/cleanParams';

const props = defineProps<{
  clientSecret: string,
  bookingToken: string,
}>();

const isLoading = ref(false);

const stripe = ref<Stripe | null>(null);
const elements = ref<ReturnType<Stripe['elements']> | null>(null);
const paymentElement = ref<StripePaymentElement | null>(null);

const initializeStripe = async () => {
  stripe.value = await loadStripe(import.meta.env.VITE_STRIPE_KEY);
  if (!stripe.value) return;

  elements.value = stripe.value.elements({
    clientSecret: props.clientSecret,
    loader: 'auto',
  });
  paymentElement.value = elements.value.create('payment');
  paymentElement.value.mount('#payment-element');
};

const handleSubmit = async () => {
  if (!stripe.value || !elements.value) return;

  isLoading.value = true;

  try {
    const { error } = await stripe.value.confirmPayment({
      elements: elements.value,
      confirmParams: {
        return_url: `${cleanParams(window.location.href, ['booking_token', 'livemode', 'client_secret', 'source', 'redirect_status'])}&booking_token=${props.bookingToken}`,
      },
    });

    if (error) {
      console.error(error);
      throw new Error(error.message);
    }

    console.log('Payment successful!');
  } catch (error) {
    console.error(`Stripe payment error: ${error}`);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  initializeStripe();
});

</script>