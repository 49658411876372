import { ref } from "vue";
import { PaymentData } from "@/types/checkout";

export function useStripePayment() {
  const loading = ref(true);
  const error = ref<string | null>(null);

  const initPaymentSession = async (token: string, paymentData: PaymentData) => {
    const apiUrl = `${import.meta.env.VITE_PAYMENT_API_URL}/payment/init`;

    const isWallet = ['google_pay', 'apple_pay'].includes(paymentData.payment_method);
    if (isWallet) {
      paymentData.payment_method = 'credit_card';
    }

    try {
      const response = await fetch(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        method: "POST",
        body: JSON.stringify(paymentData),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(`Failed to create init payment session: ${data.errors}`);
      }

      const data = await response.json();
      return data.data;
    } catch (err) {
      const errMsg = err instanceof Error ? err.message : String(err);
      error.value = errMsg;
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  const checkPaymentConfirmation = async (token: string) => {
    const apiUrl = `${import.meta.env.VITE_PAYMENT_API_URL}/payment/verify`;

    try {
      const response = await fetch(apiUrl, {
        headers: {
          'Authorization': token,
        },
        method: "POST",
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(`Failed to create confirm payment: ${data.errors}`);
      }
      const data = await response.json();
      return data.data;

    } catch (err) {
      const errMsg = err instanceof Error ? err.message : String(err);
      error.value = errMsg;
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  return {
    loading,
    error,
    initPaymentSession,
    checkPaymentConfirmation,
  }
}