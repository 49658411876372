import { ref } from 'vue';

interface AvailabilityDate {
  start_time: string[]; // Array of times in HH:mm format
  day: string; // YYYY-MM-DD format
}

interface Availability {
  language: string; // e.g., "de", "en", "nl", "da"
  dates: AvailabilityDate[];
}

export function useProductAvailabilities() {
  const loading = ref(false);
  const error = ref<string | null>(null);

  const availabilities = ref<Availability[]>([]);

  const fetchAvailabilities = async (productId: string) => {
    if (!productId) return;

    const apiUrl = `${import.meta.env.VITE_INTEGRATION_API_URL}/availability/${productId}?filter={"capacity":true}`;
    loading.value = true;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch availabilities[${productId}]: ${response.statusText}`);
      }

      const data = await response.json();
      availabilities.value = data.data[0].availabilities;
    } catch (err) {
      error.value = err instanceof Error ? err.message : String(err);
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  return { loading, error, fetchAvailabilities, availabilities };
}
